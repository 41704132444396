import { blog1 } from './Blog1/blog1';
import { blog2 } from './Blog2/blog2';
import { blog3 } from './Blog3/blog3';

const content = {
  'Top-5-real-world-use-cases-of-blockchain-technology': blog1,
  'Which-platforms-should-you-use-to-buy-NFTs-in-2023': blog2,
  'Top-5-Metaverse-Games,-2023-edition': blog3,
};

export default content;
